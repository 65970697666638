import {
  ChatAlt2Icon,
  ClockIcon,
  GlobeIcon,
  SpeakerphoneIcon,
} from "@heroicons/react/outline";
import React from "react";

import broadcastSrc from "../../images/broadcast.png";
import bigPictureSrc from "../../images/big-picture.png";
import timezoneSrc from "../../images/timezone.png";
import calendarSrc from "../../images/calendar.png";

const Features = () => {
  return (
    <div className="relative bg-white pb-32 overflow-hidden">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base font-semibold text-indigo-600 tracking-wide uppercase">
            Features
          </h2>
          <p className="mt-1 text-4xl font-extrabold text-gray-900 sm:text-5xl sm:tracking-tight lg:text-6xl">
            Standups on autopilot
          </p>
          <p className="max-w-xl mt-3 mx-auto text-xl text-gray-500">
            StandupWizard helps your team run seamlessly by automating the
            entire status check-in process
          </p>
        </div>
      </div>
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <SpeakerphoneIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Keep your team aligned
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  StandupWizard ensures your entire team remains on the same
                  page by facilitating collaboration and communication. Team
                  members stay accountable and transparent by automatically
                  having their standup reports shared to a central team channel
                  for everyone to see.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-16 lg:mt-0">
            <div className="p-4 sm:-mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={broadcastSrc}
                alt="Broadcast to channels"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <ClockIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Reduce meetings and optimize time
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Streamline the standup process and optimize your team's time
                  by reducing the number of meetings required day-to-day. With
                  less meetings, your team can spend more time heads down
                  without interruption working towards important goals.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="p-4 sm:-ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={calendarSrc}
                alt="Reduce meetings"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <ChatAlt2Icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Get a clear view of the big picture
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  StandupWizard comes equipped with a powerful dashboard that
                  provides insight and the ability to filter on all reports over
                  time so managers and stakeholders can get a clear picture and
                  identify challenges or roadblocks early.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-16 lg:mt-0">
            <div className="p-4 sm:-mr-48 sm:pl-6 md:-mr-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                src={bigPictureSrc}
                alt="StandupWizard Dashboard"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="my-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:grid-flow-col-dense lg:gap-24">
          <div className="px-4 max-w-xl mx-auto sm:px-6 lg:py-32 lg:max-w-none lg:mx-0 lg:px-0 lg:col-start-2">
            <div>
              <div>
                <span className="h-12 w-12 rounded-md flex items-center justify-center bg-indigo-600">
                  <GlobeIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  Remain flexible in a distributed world
                </h2>
                <p className="mt-4 text-lg text-gray-500">
                  Multiple timezones and irregular schedules make finding a
                  meeting time that works for your whole team extremely
                  difficult. StandupWizard runs asynchronous standups to be more
                  flexible with your team's time while still achieving great
                  results.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:mt-16 lg:mt-0 lg:col-start-1">
            <div className="p-4 sm:-ml-48 sm:pr-6 md:-ml-16 lg:px-0 lg:m-0 lg:relative lg:h-full">
              <img
                className="w-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 lg:absolute lg:right-0 lg:h-full lg:w-auto lg:max-w-none"
                src={timezoneSrc}
                alt="Timezone support"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;
