import {
  AnnotationIcon,
  AdjustmentsIcon,
  CalendarIcon,
  ChartBarIcon,
  ClockIcon,
  DownloadIcon,
  FilterIcon,
  UserIcon,
} from "@heroicons/react/outline";
import React from "react";

const features = [
  {
    name: "Automated Reminders",
    description:
      "Configure standup days and pick a standup time, StandupWizard will take care of the rest for you.",
    icon: CalendarIcon,
  },
  {
    name: "Frequency Customization",
    description:
      "Each standup can be customized to run on selected report days every week, every other week, and so on.",
    icon: AdjustmentsIcon,
  },
  {
    name: "Advanced Report Filtering",
    description:
      "Use the StandupWizard dashboard to review and filter on all historical standup reports with ease.",
    icon: FilterIcon,
  },
  {
    name: "Reporting History",
    description:
      "Look at a specific participant's entire reporting history for a given standup over a desired period of time.",
    icon: UserIcon,
  },
  {
    name: "Reporting Statistics",
    description:
      "The StandupWizard dashboard provides statistics on standups as well as individual participant reporting.",
    icon: ChartBarIcon,
  },
  {
    name: "Timezone Support",
    description:
      "Standup reminders can be sent out in a specified timezone or in the participant's own timezone.",
    icon: ClockIcon,
  },
  {
    name: "Participant Flexibility",
    description:
      "Participants can snooze reminders if busy, mark themself absent for the day, or even report manually anytime.",
    icon: AnnotationIcon,
  },
  {
    name: "Download Standup Data",
    description:
      "Easily download report data for a given day across the team or over time for a specific participant.",
    icon: DownloadIcon,
  },
];

const SubFeatures = () => {
  return (
    <div className="bg-indigo-700">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:pt-24 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white tracking-tight">
          Everything you need to run an effective team
        </h2>
        <p className="mt-4 max-w-3xl text-lg text-indigo-200">
          StandupWizard is made to fit any team and has what you need to ensure
          team collaboration and communication never slows down.
        </p>
        <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-4 lg:gap-x-8 lg:gap-y-16">
          {features.map((feature) => (
            <div key={feature.name}>
              <div>
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
                  <feature.icon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </div>
              <div className="mt-6">
                <h3 className="text-lg font-medium text-white">
                  {feature.name}
                </h3>
                <p className="mt-2 text-base text-indigo-200">
                  {feature.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SubFeatures;
