import React, { useEffect, useState } from "react";

import heroSrc from "../../images/hero.png";
import hero2Src from "../../images/hero-2.png";

import AddToSlack from "../add-to-slack";
import SlackUI from "../slack-ui";
import Header from "../header";

const srcMap = {
  1: heroSrc,
  2: hero2Src,
};

const Hero = ({ search }) => {
  const [src, setSrc] = useState(1);

  useEffect(() => {
    if (search.length) {
      const urlParams = new URLSearchParams(search);
      const email = urlParams.get("email");
      if (
        typeof window !== "undefined" &&
        typeof window.plausible === "function" &&
        email
      ) {
        window.plausible("Email Click", {
          props: { code: email || null },
        });
      }
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setSrc(src === 1 ? 2 : 1);
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  });

  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
          <div className="relative h-full">
            <svg
              className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
              />
            </svg>
            <svg
              className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
              width={404}
              height={784}
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={784}
                fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
              />
            </svg>
          </div>
        </div>

        <div className="relative pt-6 pb-16 sm:pb-24">
          <Header />
          <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">Run <span className="text-indigo-600">automated</span> standups</span>
                <span className="block">directly in Slack</span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Keep your distributed team accountable and running effectively by
                asynchronously collecting status updates directly in Slack.
                Create your first team standup in minutes.
              </p>
              <div className="mt-10">
                <AddToSlack />
              </div>
            </div>
          </div>
          <div className="max-w-6xl mt-16 mx-auto px-4 sm:px-6">
            <div className="hidden lg:block">
              <SlackUI />
            </div>
            <div className="max-w-5xl mt-16 mx-auto px-4 sm:px-6 lg:hidden">
              <iframe
                className="w-full aspect-video shadow-lg"
                src="https://www.youtube.com/embed/HcG8t52uHpY?rel=0&autohide=2"
                title="StandupWizard Video"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
