import React from "react";

import CallToAction from "../components/call-to-action";
import Hero from "../components/hero";
import Features from "../components/features";
import Footer from "../components/footer";
import SubFeatures from "../components/sub-features";
import Metadata from "../components/metadata";

const IndexPage = ({ location }) => {
  return (
    <>
      <Metadata title="StandupWizard for Slack - Automated asynchronous standups for distributed teams" />
      <Hero search={location.search} />
      <Features />
      <SubFeatures />
      <CallToAction />
      <Footer />
    </>
  );
};

export default IndexPage;
